import { network } from "./network";

// 场馆列表
export let List = (data) => network("/h5/reservation/getReservationMuseumList", data);
export let City = (data) => network('/h5/reservation/cascadeProvinceCity',data)
export let TravelDate = (data) =>network('/h5/reservation/getReservationMuseumArrivalRange',data)

export let OrderDetail = (data) =>network('/h5/reservation/getReservationMuseumById',data)
//获取预约博物馆
export let ReservationMuseumById = (data) =>network('/h5/reservation/getReservationMuseumById',data)
//身份证判断
export let Card = (data) =>network('/h5/reservation/checkIdCard',data)
//历史预约订单列表接口
export let myOrder = (data) =>network('/h5/reservation/getReservationPeopleForH5',data,'post')
//获取验证码
export let Number = (data) =>network('/h5/reservation/getVerificationCode',data)
//登录
export let Login = (data) =>network('/h5/reservation/loginH5',data,'post')
//浙里办登录
export let LoginOuter = (data) =>network('/h5/reservation/loginOuter',data,'post')
//获取到馆时间
export let getSession = (data) =>network('/h5/reservation/getSessionTime',data,'post')
//立即预约
export let Appointment = (data) =>network('/h5/reservation/saveReservationPeopleForZLBH5',data,'post')
//获取电子凭证
export let Electronic = (data) =>network('/h5/reservation/getReservationDetail',data)

//删除预约订单
export let deletOrder = (data) =>network('/h5/reservation/deleteH5Reservation',data)
//取消预约订单
export let cancelOrder = (data) =>network('/h5/reservation/cancelH5Appointment',data)
//自主核销
export let hexiao = (data) =>network('/h5/reservation/h5WriteOff',data)
// export let picpreFix = 'https://zm.bowu66.com/'
export let picpreFix = 'http://static.bowu66.com/'
export let localPic ='http://static.bowu66.com/wenlv/'