import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "../utils/rem"
import { Icon,Tab,Tabs,Search,Popup,Cell,Field,DatetimePicker,Form,Picker,Checkbox, CheckboxGroup,Dialog,Toast} from 'vant';
Vue.config.productionTip = false
Vue.use(Search)
Vue.use(Popup)
Vue.use(Cell)
Vue.use(Field);
Vue.use(DatetimePicker);
Vue.use(Form)
Vue.use(Picker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Dialog);
Vue.use(Toast)
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
